<template lang="pug">
div(v-if='hasProtexApplication')
  slot
</template>

<script lang="ts" setup>
const { $uiEvents } = useNuxtApp()
const fitmentDisplayStore = useFitmentDisplayStore()
const fitmentStore = useFitmentStore()
const log = useLogger('ProtexWrapper')
// Define Props
const { productLineData } = defineProps<{
  productLineData: ProductLine
}>()

const { data: hasProtexApplication, refresh } = await useAsyncData('protex-data', async () => {
  if (!fitmentDisplayStore.hasFullFitment) return false

  // Check if our product + fitment combination has protex
  const resp = await $fetch(`/api/protex/${productLineData.productLineSlug}/`, {
    query: {
      make: fitmentStore.$state.makeSlug,
      model: fitmentStore.$state.modelSlug,
      year: fitmentStore.$state.year,
      bed: fitmentStore.$state.bedSlug,
      body: fitmentStore.$state.bodySlug,
      engine: fitmentStore.$state.engineSlug,
    },
  }).catch((e) => {
    // If we couldn't fetch because of a network error, we won't have a status to check
    if (e.status && e.status !== 404) {
      log.error('Failed to fetch protex data', e)
    }
  })

  return resp?.hasApplications ?? false
})

onMounted(() => {
  emitBuilderButtonViewed()
})

// Hit protex API again if fitment changes
watch(fitmentStore.$state, async () => {
  await refresh()
  emitBuilderButtonViewed()
})

function emitBuilderButtonViewed() {
  if (!hasProtexApplication.value) return

  $uiEvents.$emit('BuilderButtonViewed', productLineData)
}
</script>
