<template lang="pug">
div(class='max-w-6xl mx-auto')
  Gallery(:gallery='data.gallery' :show-thumbs='showThumbs')

  div(class='flex flex-col mt-4 lg:flex-row lg:mt-0')
    ProtexWrapper(v-if='isIntegrationEnabled("protex")' :product-line-data='data')
      UniversalLink(
        link='PROTEX'
        element='button'
        class='flex items-center justify-center font-bold py-2 px-6 mb-2 lg:mr-2 lg:mb-0 border rounded text-inherit'
        @click='$uiEvents.$emit("BuilderButtonClicked", data)'
      )
        img(src='/images/3dSpin.svg' alt='' class='w-6 h-4 mr-2')
        | See it on your {{ fitmentStore.model }}

    button(
      v-if='hasCustomerPhotos'
      type='button'
      class='flex items-center justify-center font-bold py-2 px-6 border rounded'
      @click='showCustomerPhotos'
    )
      img(src='/images/customPhoto.svg' alt='' class='w-[18px] h-3.5 mr-2')
      | Customer Photos
</template>

<script setup lang="ts">
const { $scrollTo } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const fitmentStore = useFitmentStore()

const { data, showThumbs, hasCustomerPhotos } = defineProps<{
  data: ProductLine
  showThumbs?: boolean
  hasCustomerPhotos: boolean
}>()

function showCustomerPhotos() {
  const element = document.getElementById('scrollTarget-photos')
  if (element) $scrollTo(element, 500)
}
</script>
